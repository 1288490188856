@use "../styles/theme.scss";

* {
  box-sizing: border-box;
}

html, body, :global(#root), :global(#ui-root), :global(.home-root) {
  margin: 0;
  height:100%;
}

body {
  background: #000;
  color: theme.$text1-color;
}

:global(#root), :global(#ui-root), :global(.home-root) {
  display:flex; 
  flex-direction:column;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

main {
  display: block;
  order: -1;
  flex: 1;

  @media(min-width: theme.$breakpoint-lg) {
    order: 0;
    display: flex;
    flex-direction: column;
  }
}
