@use "../styles/theme.scss";

:local(.baseBtn) {
  &:hover {
    color: theme.$theme-orange !important;
    svg {
      color: theme.$theme-orange !important;
    }
  }
}

:local(.lg) {
  &:hover {
    color: theme.$theme-orange !important;
    svg {
      color: theme.$theme-orange !important;
    }
  }
  @media (min-width: theme.$breakpoint-lg) and (min-height: theme.$breakpoint-vr) {
    svg {
      height: 20px;
      width: 20px;
    }
  }
}
